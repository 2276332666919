import { useDisclosure } from '@chakra-ui/hooks';
import { useGetScheduled } from 'api/scheduled';
import React from 'react';
import ScheduledTable from './components/ScheduledTable';
import PageHeader from 'components/header/PageHeader';

const DashboardComposeScheduled = () => {
  const [filter, setFilter] = React.useState('');
  const getScheduled = useGetScheduled();
  const addWorkflowModalActions = useDisclosure();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      {/* Header */}
      <PageHeader
        heading={'Scheduled Notifications'}
        text={
          <>
            View the list of scheduled notifications. You can also cancel them.
          </>
        }
        textParentClass="mb-0"
      />

      {/* Tables */}
      <ScheduledTable
        tableData={getScheduled?.data ?? []}
        editOpen={addWorkflowModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
        loading={getScheduled.isLoading}
      />
    </div>
  );
};

export default DashboardComposeScheduled;
