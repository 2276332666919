import { useDisclosure } from '@chakra-ui/hooks';
import { useGetTranslations } from 'api/translations';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import { useTranslationStore } from 'store/translationStore';
import { useAppLevel } from 'utils/use-app-level';
import TranslationModal from './components/TranslationModal';
import TranslationTable from './components/TranslationTable';
import { usePaginationApiState } from 'components/table/pagination-state';
import HeaderDoc from 'components/header/HeaderDoc';
import { usePlanLimit } from 'utils/use-plan-limit';

const Translations = () => {
  const [filter, setFilter] = React.useState('');
  const { pagination, setPagination } = usePaginationApiState();
  const planLimit = usePlanLimit();
  const getTranslations = useGetTranslations({
    page: pagination.pageIndex,
    limit: pagination.pageSize,
    search: filter,
  });
  const addWorkflowModalActions = useDisclosure();
  const { setTranslation, setTranslationModalState } = useTranslationStore(
    state => state,
  );
  const { isDefaultApp } = useAppLevel();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <TranslationModal
        isOpen={addWorkflowModalActions.isOpen}
        onClose={addWorkflowModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        setFilter={setFilter}
        filter={filter}
        paginationType={'api'}
        setPagination={setPagination}
        showSearchBar={false}
        handleCreate={() => {
          if (!planLimit.limits.translations) {
            planLimit.actions.setText(planLimit.texts.not_supported);
            return planLimit.actions.onOpen();
          }

          setTranslation(null);
          setTranslationModalState('add');
          addWorkflowModalActions.onOpen();
        }}
        buttonText={isDefaultApp ? 'Create Translation' : ''}
        heading={'Translations'}
        text={
          <>
            Translations let you define message strings for different languages,
            which can be dynamically used in workflows.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/features/translations/overview"
              text="Learn more"
            />
          </>
        }
      />

      {/* Tables */}
      <TranslationTable
        totalPages={getTranslations?.data?.totalPages}
        pagination={pagination}
        setPagination={setPagination}
        loading={getTranslations.isLoading}
        tableData={getTranslations?.data?.results ?? []}
        editOpen={addWorkflowModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
      />
    </div>
  );
};

export default Translations;
