import { useDisclosure } from '@chakra-ui/hooks';
import { useGetWorkflows } from 'api/workflows';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import useWorkflowStore from 'store/workflowStore';
import WorkflowModal from './components/WorkflowModal';
import WorkflowTable from './components/WorkflowTable';
import { useAppLevel } from 'utils/use-app-level';

const Workflow = () => {
  const [filter, setFilter] = React.useState('');
  const getWorkflows = useGetWorkflows();
  const addWorkflowModalActions = useDisclosure();
  const { setWorkflow, setWorkflowModalState } = useWorkflowStore(
    state => state,
  );
  const { isDefaultApp } = useAppLevel();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <WorkflowModal
        isOpen={addWorkflowModalActions.isOpen}
        onClose={addWorkflowModalActions.onClose}
      />
      {/* Header */}
      <PageHeader
        setFilter={setFilter}
        filter={filter}
        paginationType={'table'}
        handleCreate={() => {
          setWorkflow(null);
          setWorkflowModalState('add');
          addWorkflowModalActions.onOpen();
        }}
        buttonText={isDefaultApp ? 'Create Workflow' : ''}
        heading={'Notification Workflows'}
        text={
          <>
            In Engagespot, every notification is a workflow—simple and
            streamlined.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/features/workflows/introduction"
              text="Learn more"
            />
          </>
        }
      />

      {/* Tables */}
      <WorkflowTable
        isDefaultApp={isDefaultApp}
        loading={getWorkflows.isLoading}
        tableData={getWorkflows?.data ?? []}
        editOpen={addWorkflowModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
      />
    </div>
  );
};

export default Workflow;
