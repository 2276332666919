import { useMutateWorkflow } from 'api/workflows';
import { CustomDeleteModal } from 'components/modal';
import useWorkflowStore from 'store/workflowStore';
import useCustomToast from 'utils/use-toast';

function DeleteWorkflowModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { workflow } = useWorkflowStore(state => state);
  const mutateWorkflow = useMutateWorkflow();
  const toast = useCustomToast();

  const handleSubmit = () => {
    console.log('firing');
    mutateWorkflow.mutate(
      {
        type: 'delete',
        workflowId: workflow.id,
      },
      {
        onSuccess: () => {
          toast.success('workflow removed successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error?.message);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={mutateWorkflow.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Workflow"
      text={'Are you sure to remove this workflow?'}
    />
  );
}

export default DeleteWorkflowModal;
