import { ExploreCardsProps } from './types';

export const exploreSectionData: ExploreCardsProps[] = [
  {
    heading: 'Create a workflow',
    content: 'In Engagespot, every notification is a workflow.',
    linkUrl: '/admin/workflow',
    linkText: 'Create workflow',
    newTab: false,
  },
  {
    heading: 'Add In-App <Inbox/>',
    content:
      'Add an in-app inbox to your app using front-end component libraries.',
    linkUrl:
      'https://docs.engagespot.co/docs/features/in-app-inbox/introduction',
    linkText: 'Read docs',
    newTab: true,
  },
  {
    heading: 'Join Discord',
    content:
      'Join our community and get quick answers from 1000s of developers',
    linkUrl: 'https://disboard.org/server/join/936616763930587136',
    linkText: 'Join Discord',
    newTab: true,
  },
  {
    heading: 'Bubble.io Guide',
    content: 'Learn how to integrate Engagespot with Bubble.io',
    linkUrl:
      'https://docs.engagespot.co/docs/plugins/bubble-io-plugin/installing-bubble-plugin/',
    linkText: 'Read docs',
    newTab: true,
  },
];
